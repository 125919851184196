<template>
    <div class='list_content'>
        <h2 style="text-align: center;">手动分配</h2>
        <el-form ref="form" :model="form" label-width="80px">
            <el-form-item label="姓名 : ">
                <div style="width:200px;background-color:silver;padding-left:20px;">{{ form.name }}</div>
            </el-form-item>

            <el-form-item label="手机号 : ">
                <div style="width:200px;background-color:silver;padding-left:20px">{{ form.telphone }}</div>
            </el-form-item>

            <el-form-item label="年龄 : ">
                <div style="width:200px;background-color:silver;padding-left:20px">{{ form.age }}</div>
            </el-form-item>

            <el-form-item label="性别 :">
                <div style="width:200px;background-color:silver;padding-left:20px">{{ form.gender }}</div>
            </el-form-item>

            <el-form-item label="来源平台" v-if="fpShow">
                <el-select v-model="form.app_name" placeholder="请选择">
                    <el-option label="字节-抖音" value="字节-抖音"></el-option>
                    <el-option label="字节-今日头条" value="字节-今日头条"></el-option>
                    <el-option label="微信" value="微信"></el-option>
                    <el-option label="快手" value="快手"></el-option>
                    <el-option label="百度" value="百度"></el-option>
                    <el-option label="小红书" value="小红书"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="分配给谁 : " v-if="fpShow">
                <el-select filterable v-model="form.user_id" placeholder="请选择">
                    <el-option v-for="item in lv3User" :key="item.user_id" :label="item.user_name"
                        :value="item.user_id"></el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="创建时间 :" v-if="fpShow">
                <el-input @input="fangDouCheckTime()" v-model="form.create_time"></el-input>
                <span id="timeCheckOk" style="font-size:12px;color:green;display:none;">日期格式验证通过</span>
                <span id="timeCheckNo" style="font-size:12px;color:red;display:none;">日期格式不合法</span>
            </el-form-item>

            <el-button type="primary" id="userSearch" @click="inputCurrent">当前时间</el-button>
            <el-button type="primary" @click="onSubmit">确认</el-button>
            <el-button @click="cancel">取消</el-button>
        </el-form>
    </div>
</template>

<script>
import LeftMenu from '@/components/left.vue'
import HeaderTop from '@/components/top.vue'
import Footer from '@/components/footer.vue'

export default {
    name: 'SDFPView',
    // 注册组件
    components: {
        LeftMenu,
        HeaderTop,
        Footer
    },
    props: {},
    // data() 为变量赋值等
    data() {
        return {
            form_id: 0,
            cache_user: {},
            form: {
                name: null,
                telphone: null,
                age: null,
                gender: null,
                create_time: null,
            },
            fpShow: false, // 是否显示手动分配
            lv3User: [],
            checkCreateTimeTimer: null,
        }
    },

    created() {
        // 从参数获取userid
        this.form_id = this.$route.query.form_id
        // 从缓存获取 登录用户
        this.cache_user = JSON.parse(sessionStorage.getItem('user'))
        this.onload()
    },

    methods: {
        inputCurrent() {
            this.form.create_time = this.$dateFormat(new Date(), "YYYY-MM-DD hh:mm:ss")
            this.checkCreateTime(this.form.create_time)
        },
        fangDouCheckTime() {
            if (this.checkCreateTimeTimer) {
                clearTimeout(this.checkCreateTimeTimer);
            }
            this.checkCreateTimeTimer = setTimeout(() => {
                this.checkCreateTime(this.form.create_time)
            }, 500);
        },
        checkCreateTime(createTime) {
            console.log("createTime: ", createTime);
            console.log("createTime.substring(0, 2): ", createTime.substring(0, 2));
            if (createTime.substring(0, 2) != "20") {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            };
            let timeArr = createTime.split(" ");
            console.log("timeArr: ", timeArr);
            if (timeArr.length != 2) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            };
            let nyr = timeArr[0].split("-");
            if (nyr.length != 3) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            }

            console.log("nyr[0]: ", nyr[0], nyr[1], nyr[2]);
            if (nyr[0].length != 4 || nyr[1].length != 2 || nyr[2].length != 2) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            }
            let sfm = timeArr[1].split(":");
            if (sfm.length != 3) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            }
            console.log("sfm[0]: ", sfm[0], sfm[1], sfm[2]);
            if (sfm[0].length != 2 || sfm[1].length != 2 || sfm[2].length != 2) {
                // alert("日期格式不合法 !");
                document.getElementById("timeCheckNo").style.display = "block";
                document.getElementById("timeCheckOk").style.display = "none";
                this.formatTimeCHeck = false;
                return
            }
            // alert("日期格式正确 !")
            document.getElementById("timeCheckOk").style.display = "block";
            document.getElementById("timeCheckNo").style.display = "none";
            this.formatTimeCHeck = true;

        },
        onload() {
            let that = this
            // 添加权限
            if (that.cache_user.user_level == 1 || that.cache_user.user_level == 2) {
                that.fpShow = true
            }

            let req = {
                form_id: Number(that.form_id)
            }
            this.myAxios.post(that.myBaseurl + '/form/info', req)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log(res.data.Data);
                        that.form = res.data.Data
                    } else {
                        alert("获取表单信息失败!")
                    }
                })

            this.myAxios.post(that.myBaseurl + '/user/lv3', req)
                .then(function (res) {
                    if (res.data.Code === '200') {
                        console.log(res.data.Data);
                        that.lv3User = res.data.Data
                    } else {
                        alert("获取lv3User用户信息失败!")
                    }
                })
        },

        onSubmit() {
            console.log('开始修改!');
            let that = this
            that.form.form_id = Number(that.form.id)
            that.form.user_id = Number(that.form.user_id)
            that.form.form_status = Number(that.form.form_status)

            if (that.fpShow) {
                that.myAxios.post(that.myBaseurl + '/form/allot', that.form).then(function (res) {
                    alert(res.data.Msg)
                })
            }
        },

        cancel() {
            window.history.go(-1);
        }
    }
}

</script>

<style scoped="scoped">
/*大框体样式*/
.el-main {
    height: calc(100vh - 120px);
}

.list_content {
    max-width: 800px;
}

.el-form-item__label {
    max-width: 200px !important;
}

#userSearch {
    background-color: rgb(51, 207, 121);
    background-image: none;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: 12px;
    height: 42px;
    line-height: 42px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 85px;
    margin-left: 80px;
    margin-right: 20px;
    color: white;
}
</style>
